/* global alert */
/* global FileReader */

import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Webcam from './Webcam';
import fileDialog from 'file-dialog';
import PropTypes from 'prop-types';
import Image from 'react-bootstrap/Image';
import Styles from './Webcam.module.scss';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import selfie from 'selfie.jpg';

function Selfie(props) {
  const [showWebcam, setShowWebcam] = useState(false);
  const [srcPhoto, setSrcPhoto] = useState(null);

  function setPhoto(src) {
    setSrcPhoto(src);
    props.onChange(src);
  }

  function abrirCameraClick() {
    setShowWebcam(true);
  }

  function apagarClick() {
    setShowWebcam(false);
    setPhoto(null);
  }

  function carregarArquivoClick() {
    fileDialog({ accept: 'image/*', multiple: false }).then((files) => {
      if (files.length !== 1) {
        return;
      }
      const arquivo = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(arquivo);
      reader.onloadend = () => {
        setPhoto(reader.result);
      };
      reader.onerror = (event) => {
        alert('Erro ao carregar arquivo, tente novamente');
        console.error(event);
      };
    });
  }

  function captureWebcamHandler(src) {
    setPhoto(src);
    setShowWebcam(false);
  }

  return (
    <Row {...props} className='gx-2'>
      {showWebcam && <Webcam onCapture={captureWebcamHandler} />}
      <Col xs={12} lg={6} className='p-4'>
        <h4 className='mb-3'>Foto de Identificação</h4>
        <p className='text-muted font-weight-bold mb-5'>
          Tire uma selfie segurando um de seus documentos de identificação e
          envie através dos botões abaixo para comprovar sua identidade.
        </p>
        {srcPhoto ? (
          <Button variant='link' onClick={apagarClick}>
            Apagar
          </Button>
        ) : (
          <div className='d-flex flex-row justify-content-between'>
            <Button
              variant='primary'
              className='mr-1 w-100'
              onClick={abrirCameraClick}
            >
              Abrir Câmera
            </Button>
            <Button
              variant='secondary'
              className='ml-1 w-100'
              onClick={carregarArquivoClick}
            >
              Carregar Arquivo
            </Button>
          </div>
        )}
      </Col>
      <Col xs={12} lg={6}>
        {srcPhoto !== null ? (
          <Image src={srcPhoto} height='100%' width='100%' className='border' />
        ) : (
          <div
            style={{ backgroundImage: `url(${selfie})` }}
            className={'imagem-selfie ' + Styles.imagemSelfie}
          />
        )}
      </Col>
    </Row>
  );
}

Selfie.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default Selfie;
