import React from 'react';
import Alert from 'react-bootstrap/Alert';

export default class FormAlert extends React.Component {
  state = {
    mensagem: null
  };

  alert = null;

  handleClose = () => {
    this.setState({ mensagem: null });
  };

  async show(msg) {
    await this.setState({ mensagem: msg });
    this.alert.scrollIntoView();
  }

  render = () => {
    if (this.state.mensagem) {
      return (
        <Alert
          ref={(ref) => (this.alert = ref)}
          variant='danger'
          onClose={this.handleClose}
          dismissible
        >
          {this.state.mensagem}
        </Alert>
      );
    }
    return null;
  };
}
