import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import FormAlert from './FormAlert';
import moment from 'moment';
import Col from 'react-bootstrap/Col';
import InputMask from 'react-input-mask';
import DemaisDadosRenderer from './DemaisDadosRenderer';
import classNames from 'classnames';
import Selfie from './Selfie';
import Container from 'react-bootstrap/Container';
import Styles from './participante-form.module.css';

const camposPadrao = [
  'nome',
  'email',
  'cpf',
  'dataNascimento',
  'celular',
  'matriculaFuncional',
  'urna'
];

function populate({ participante, evento }) {
  const getValue = (campo) => {
    if (participante === null) {
      return '';
    }
    if (camposPadrao.find((c) => c === campo)) {
      switch (campo) {
        case 'dataNascimento':
          return participante[campo]
            ? moment(participante[campo], 'YYYY-MM-DD').format('DD/MM/YYYY')
            : '';
        case 'urna':
          return participante[campo] ? participante[campo].id : '';
        default:
          return participante[campo] ? participante[campo] : '';
      }
    } else {
      if (participante.demaisDados) {
        return participante.demaisDados[campo];
      } else {
        return '';
      }
    }
  };

  const valorDefault = {};
  camposPadrao.forEach((campo) => {
    const valor = getValue(campo);
    valorDefault[campo] = valor === null ? '' : valor;
  });

  if (evento) {
    evento.camposCustomizaveis.forEach((campo) => {
      const valor = getValue(campo.nome);
      valorDefault[campo.nome] = valor === null ? '' : valor;
    });
  }

  return valorDefault;
}

function ParticipanteForm({
  evento,
  onSubmited,
  formName,
  submitHandler,
  participante,
  readOnly,
  onLoadingChange,
  onChange,
  urnas
}) {
  const alert = useRef(null);
  const [formValues, setFormValues] = useState(null);
  const [ajaxValidation, setAjaxValidation] = useState({});
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  const edicao = participante !== null;

  useEffect(() => {
    setFormValues(populate({ participante, evento }));
  }, [participante, evento]);

  async function handleSubmit(event) {
    event.preventDefault();

    if (readOnly) {
      return;
    }

    if (loading) {
      return;
    }

    const form = event.currentTarget;

    setValidated(false);

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      alert.current.show('Verifique os dados');
      return;
    }

    if (onLoadingChange) onLoadingChange(true);

    setLoading(true);

    let dataNascimento = null;

    if (
      formValues.dataNascimento &&
      moment(formValues.dataNascimento, 'DD/MM/YYYY').isValid
    ) {
      dataNascimento = moment(formValues.dataNascimento, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      );
    }

    const demaisDados = {};
    if (evento) {
      evento.camposCustomizaveis.forEach(
        (campo) => (demaisDados[campo.nome] = formValues[campo.nome])
      );
    }

    const retorno = await submitHandler({
      nome: formValues.nome,
      email: formValues.email,
      cpf: formValues.cpf,
      dataNascimento: dataNascimento,
      celular: formValues.celular,
      matriculaFuncional: formValues.matriculaFuncional,
      urna: formValues.urna,
      demaisDados: demaisDados,
      fotoFile: formValues.fotoFile
    });

    if (onLoadingChange) onLoadingChange(false);
    setLoading(false);
    if (retorno.success) {
      onSubmited(retorno.data);
    } else {
      switch (retorno.status) {
        case 400:
          alert.current.show(retorno.data.mensagem);
          break;
        case 422:
          setAjaxValidation(retorno.data);
          alert.current.show('Verifique os dados');
          break;
        default:
          break;
      }
    }
  }

  function handleChange(event) {
    const fieldName = event.target.name;
    const fieldVal = event.target.value;
    changeForm(fieldName, fieldVal);
  }

  function handleChangeSelfie(src) {
    changeForm('fotoFile', src);
  }

  function changeForm(fieldName, fieldVal) {
    const data = { ...formValues, [fieldName]: fieldVal };
    setAjaxValidation({ ...ajaxValidation, [fieldName]: null });
    setFormValues(data);
    if (onChange) {
      onChange(data);
    }
  }

  function fieldRequired(nome) {
    if (evento) {
      return evento['obrigatorio' + nome];
    } else {
      return false;
    }
  }
  const cpfDisabled = (edicao && participante.cpf) || readOnly;

  if (formValues === null) {
    return null;
  }

  return (
    <Container fluid>
      <Form
        noValidate
        validated={validated}
        id={formName || 'formParticipante'}
        onSubmit={handleSubmit}
      >
        <FormAlert ref={alert} />

        <Form.Group controlId='formNome' className={Styles.required}>
          <Form.Label>Nome</Form.Label>
          <Form.Control
            required
            disabled={edicao}
            isInvalid={ajaxValidation.nome}
            name='nome'
            onChange={handleChange}
            value={formValues.nome}
            maxLength={100}
          />
          <Form.Control.Feedback type='invalid'>
            Nome inválido
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Row>
          <Form.Group
            as={Col}
            sm='6'
            controlId='formCpf'
            className={fieldRequired('Cpf') && Styles.required}
          >
            <Form.Label>CPF</Form.Label>
            <InputMask
              mask='999.999.999-99'
              disabled={cpfDisabled}
              value={formValues.cpf}
              onChange={handleChange}
            >
              <Form.Control
                name='cpf'
                required={fieldRequired('Cpf')}
                isInvalid={ajaxValidation.cpf}
              />
            </InputMask>

            <Form.Control.Feedback type='invalid'>
              CPF inválido
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            sm='6'
            controlId='formDataNascimento'
            className={fieldRequired('DataNascimento') && Styles.required}
          >
            <Form.Label>Data de Nascimento</Form.Label>
            <InputMask
              mask='99/99/9999'
              disabled={edicao && readOnly}
              value={formValues.dataNascimento}
              onChange={handleChange}
            >
              <Form.Control
                name='dataNascimento'
                required={fieldRequired('DataNascimento')}
                isInvalid={ajaxValidation.dataNascimento}
              />
            </InputMask>
            <Form.Control.Feedback type='invalid'>
              Data de Nascimento inválida
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>

        <Form.Group
          controlId='formEmail'
          className={fieldRequired('Email') && Styles.required}
        >
          <Form.Label>Email</Form.Label>
          <Form.Control
            required={fieldRequired('Email')}
            disabled={edicao && readOnly}
            name='email'
            isInvalid={ajaxValidation.email}
            onChange={handleChange}
            value={formValues.email}
            maxLength={100}
            type='email'
            placeholder='seunome@provedor.com.br'
          />
          <Form.Control.Feedback type='invalid'>
            E-mail inválido
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Row>
          <Form.Group
            as={Col}
            sm='6'
            controlId='formCelular'
            className={fieldRequired('Celular') && Styles.required}
          >
            <Form.Label>Celular</Form.Label>
            <InputMask
              mask='(99)99999-9999'
              disabled={edicao && readOnly}
              value={formValues.celular}
              onChange={handleChange}
            >
              <Form.Control
                name='celular'
                required={fieldRequired('Celular')}
                isInvalid={ajaxValidation.celular}
              />
            </InputMask>
            <Form.Control.Feedback type='invalid'>
              Celular inválido
            </Form.Control.Feedback>
          </Form.Group>
          {evento && evento.tituloMatricula && (
            <Form.Group
              as={Col}
              sm='6'
              controlId='formMatricula'
              className={fieldRequired('Matricula') && Styles.required}
            >
              <Form.Label>{evento ? evento.tituloMatricula : ''}</Form.Label>
              <Form.Control
                required={fieldRequired('Matricula')}
                disabled={edicao && readOnly}
                isInvalid={ajaxValidation.matriculaFuncional}
                name='matriculaFuncional'
                onChange={handleChange}
                value={formValues.matriculaFuncional}
                maxLength={30}
                type='number'
              />
              <Form.Control.Feedback type='invalid'>
                Inválido
              </Form.Control.Feedback>
            </Form.Group>
          )}
        </Form.Row>
        {evento && evento.tituloUrna && (
          <Form.Group
            controlId='formUrna'
            className={fieldRequired('Urna') && Styles.required}
          >
            <Form.Label>{evento ? evento.tituloUrna : ''}</Form.Label>
            <Form.Control
              as='select'
              disabled={edicao && readOnly}
              name='urna'
              required={fieldRequired('Urna')}
              custom
              onChange={handleChange}
              value={formValues.urna}
              isInvalid={ajaxValidation.urna}
            >
              <option value={null} />
              {urnas.map((urna) => (
                <option key={urna.id} value={urna.id}>
                  {urna.descricao}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type='invalid'>
              Inválido
            </Form.Control.Feedback>
          </Form.Group>
        )}
        {evento
          ? evento.camposCustomizaveis.map((campo, i) => (
              <DemaisDadosRenderer
                key={i}
                onChange={handleChange}
                value={formValues[campo.nome]}
                field={campo.nome}
                nome={campo.titulo}
                obrigatorio={campo.obrigatorio}
                mascara={campo.mascara}
                readOnly={edicao && readOnly}
                opcoes={campo.opcoes}
              />
            ))
          : null}
        {evento && evento.incluiFotoVotante && (
          <div>
            <div
              className={classNames({
                'is-invalid border border-danger': ajaxValidation.fotoFile
              })}
            >
              <Selfie onChange={handleChangeSelfie} />
            </div>
            <div className='invalid-feedback'>
              Sua selfie é obrigatória é deve ter no máximo 10mb de tamanho
            </div>
          </div>
        )}
      </Form>
    </Container>
  );
}

ParticipanteForm.propTypes = {
  evento: PropTypes.object.isRequired,
  onSubmited: PropTypes.func.isRequired,
  formName: PropTypes.string,
  submitHandler: PropTypes.func.isRequired,
  participante: PropTypes.object,
  readOnly: PropTypes.bool,
  onLoadingChange: PropTypes.func,
  urnas: PropTypes.array,
  onChange: PropTypes.func
};

ParticipanteForm.defaultProps = {
  participante: null,
  urnas: []
};

export default ParticipanteForm;
