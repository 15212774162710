import React from 'react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

function DemaisDadosRenderer({
  field,
  obrigatorio,
  nome,
  opcoes,
  readOnly,
  onChange,
  value,
  mascara
}) {
  const fieldUpper = field.charAt(0).toUpperCase() + field.slice(1);
  return (
    <Form.Group
      controlId={'form' + fieldUpper}
      className={obrigatorio && 'required'}
    >
      <Form.Label>{nome}</Form.Label>
      {opcoes ? (
        <Form.Control
          as='select'
          disabled={readOnly}
          name={field}
          required={obrigatorio}
          custom
          onChange={onChange}
          value={value}
        >
          <option value={null} />
          {Object.keys(opcoes).map((ind) => (
            <option key={ind} value={ind}>
              {opcoes[ind]}
            </option>
          ))}
        </Form.Control>
      ) : mascara ? (
        <InputMask
          mask={mascara}
          disabled={readOnly}
          value={value}
          onChange={onChange}
        >
          <Form.Control name={field} required={obrigatorio} />
        </InputMask>
      ) : (
        <Form.Control
          name={field}
          disabled={readOnly}
          required={obrigatorio}
          onChange={onChange}
          value={value}
        />
      )}
    </Form.Group>
  );
}

DemaisDadosRenderer.propTypes = {
  field: PropTypes.string.isRequired,
  obrigatorio: PropTypes.bool,
  nome: PropTypes.string.isRequired,
  opcoes: PropTypes.object,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  mascara: PropTypes.string
};

export default DemaisDadosRenderer;
